<template>
  <div v-if="service">
    <div class="flix-form-group">
      <div class="flix-btn-remove">
        <a
          href="#"
          @click.prevent="doublicateService(index)"
          class="flix-html-a"
          ><icon id="copy-duplicate" /> {{ $t("services.editor.copy") }}</a
        >
        <a
          href="#"
          @click.prevent="removeService(index)"
          class="flix-html-a flix-text-danger"
          ><icon id="bin" /> {{ $t("services.editor.delete") }}</a
        >
      </div>
      <a
        href="#"
        @click.prevent="$store.commit('service/editService', false)"
        class="flix-html-a flix-text-darkgrey"
        ><icon id="menu-close" /> {{ $t("services.editor.close") }}</a
      >
    </div>
    <inputText
      style="width: 100%; box-sizing: border-box"
      :data="service.title"
      :callback="
        function(value, all) {
          setTitle(all, index);
        }
      "
      :help="$t('services.name.info')"
      :error="false"
      :styling="{
        input:
          'width: 100%; max-width: 300px; display: block; box-sizing: border-box'
      }"
      :placeholder="$t('services.name.title')"
      :label="$t('services.name.title')"
    />
    <serviceTime :index="index" />
    <div class="flix-flex flix-flex-inline flix-flex-center flex-gap-5">
      <inputText
        style="width: 100%; box-sizing: border-box"
        :data="service.price"
        :callback="
          function(value, all) {
            setPrice(all, index);
          }
        "
        :help="$t('services.price.info')"
        :error="false"
        :styling="{
          input:
            'width: 100%; max-width: 100px; display: block; box-sizing: border-box'
        }"
        :placeholder="$t('services.price.description')"
        :label="$t('services.price.title')"
      />
    </div>
    <serviceTags :index="index" />
    <inputTextarea
      style="width: 100%; box-sizing: border-box"
      :data="service.description"
      :callback="
        function(value, all) {
          setDescription(all, index);
        }
      "
      :help="$t('services.description.info')"
      :error="false"
      :styling="{
        input:
          'width: 100%; max-width: 600px; display: block; box-sizing: border-box'
      }"
      :placeholder="$t('services.description.title')"
      :label="$t('services.description.title')"
    />
    <div class="flix-form-group">
      <label class="flix-label" style="display: block; margin-bottom: 10px">{{
        $t("services.image.add")
      }}</label>
      <uploadImg :key="service.img" :settings="getUpload(index)" />
      <a
        v-if="service.img"
        href="#"
        class="flix-html-a flix-text-danger"
        @click.prevent="function () { deleteImage(index) }"
        ><icon id="bin"
      /></a>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    serviceTime() {
      return import("./time");
    },
    serviceTags() {
      return import("./tags");
    },
    uploadImg() {
      return import("./upload");
    }
  },
  props: {
    index: Number
  },
  data() {
    return {
      services: JSON.parse(JSON.stringify(this.$store.state.service.unsaved)),
      service: false
    };
  },
  computed: {},
  mounted() {
    this.service = this.services[this.index];
  },
  methods: {
    doublicateService(index) {
      var dublicate = JSON.parse(JSON.stringify(this.services[index]));
      dublicate.ID = new Date().getTime();
      dublicate.title += " Kopie";
      this.services.splice(index + 1, 0, dublicate);
      this.setSave();
      this.$store.commit("service/editService", index + 1);
    },
    deleteImage(index) {
      this.saveImage(index, "");
    },
    getUpload(index) {
      var services = JSON.parse(
        JSON.stringify(this.$store.state.service.unsaved)
      );
      var set = {
        onSave: function(ret) {
          this.saveImage(index, ret);
        }.bind(this),
        label:
          '<span class="flix-btn flix-btn-success">' +
          this.$t("services.image.upload") +
          "</span>",
        maxWidth: 350,
        maxHeight: 350
      };
      if (services[index].img) {
        set.image = { dataUrl: services[index].img };
      }
      return set;
    },
    saveImage(index, data) {
      this.services = JSON.parse(
        JSON.stringify(this.$store.state.service.unsaved)
      );

      if (!data) {
        this.services[index].img = "";
        this.setSave();
        return false;
      }

      var send = {
        user: false,
        data: {
          img: data.dataUrl,
          type: data.info.type,
          name: data.info.name
        }
      };
      this.$flix_post({
        data: send,
        url: "uploads/anonym",
        callback: function(ret) {
          this.services[index].img = ret.data[1].url;
          this.setSave();
        }.bind(this)
      });
    },
    getServices() {
      return JSON.parse(JSON.stringify(this.$store.state.service.unsaved));
    },
    removeService(index) {
      this.services = JSON.parse(
        JSON.stringify(this.$store.state.service.unsaved)
      );
      this.services.splice(index, 1);
      this.setSave();
      this.$store.commit("service/editService", false);
    },
    setPrice(value, index) {
      this.services = JSON.parse(
        JSON.stringify(this.$store.state.service.unsaved)
      );
      this.services[index].price = value;
      this.setSave();
    },
    setDescription(value, index) {
      this.services = JSON.parse(
        JSON.stringify(this.$store.state.service.unsaved)
      );
      this.services[index].description = value;
      this.setSave();
    },
    setTitle(value, index) {
      this.services = JSON.parse(
        JSON.stringify(this.$store.state.service.unsaved)
      );
      this.services[index].title = value;
      this.setSave();
    },
    setSave() {
      this.$store.commit("service/prefetch", this.services);
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables.sass'
ol
  margin: 0
  padding: 0
  display: flex
  align-items: center
  justify-content: flex-start
  flex-wrap: wrap
  margin-bottom: 20px
  margin-left: -10px
  margin-right: -10px
  margin-top: -10px
  li
    list-style: none
    padding: 10px
    border: 1px solid $grey
    width: 100%
    margin: 10px
    border-radius: 5px
    position: relative
    padding: 20px
    box-sizing: border-box
    a.flix-btn-end
      text-decoration: none
      margin-left: 5px
      justify-content: flex-start
      gap: 5px
      color: $dark-grey
      .icon
        color: $middle-grey
        font-size: 20pt
        &.active
          color: $success
    .flix-btn-remove
      margin-right: 10px
      position: absolute
      right: 0
      top: 20px
      font-size: 12pt
      display: flex
      align-items: center
      justify-content: center
      gap: 10px
      a
        color: $dark-grey
    .down
      display: none
    .right
      display: flex


@media(max-width: 500px)
  ol
    justify-content: center
    li
      flex-direction: column
      a.flix-btn-end
        margin: 10px 0 0 0
      .flix-btn-remove
        margin: 0 0 50px 0
        display: flex
        position: relative
        font-size: 10pt
      .down
        display: flex
      .right
        display: none
</style>
