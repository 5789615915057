var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.service)?_c('div',[_c('div',{staticClass:"flix-form-group"},[_c('div',{staticClass:"flix-btn-remove"},[_c('a',{staticClass:"flix-html-a",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.doublicateService(_vm.index)}}},[_c('icon',{attrs:{"id":"copy-duplicate"}}),_vm._v(" "+_vm._s(_vm.$t("services.editor.copy")))],1),_c('a',{staticClass:"flix-html-a flix-text-danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.removeService(_vm.index)}}},[_c('icon',{attrs:{"id":"bin"}}),_vm._v(" "+_vm._s(_vm.$t("services.editor.delete")))],1)]),_c('a',{staticClass:"flix-html-a flix-text-darkgrey",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$store.commit('service/editService', false)}}},[_c('icon',{attrs:{"id":"menu-close"}}),_vm._v(" "+_vm._s(_vm.$t("services.editor.close")))],1)]),_c('inputText',{staticStyle:{"width":"100%","box-sizing":"border-box"},attrs:{"data":_vm.service.title,"callback":function(value, all) {
        _vm.setTitle(all, _vm.index);
      },"help":_vm.$t('services.name.info'),"error":false,"styling":{
      input:
        'width: 100%; max-width: 300px; display: block; box-sizing: border-box'
    },"placeholder":_vm.$t('services.name.title'),"label":_vm.$t('services.name.title')}}),_c('serviceTime',{attrs:{"index":_vm.index}}),_c('div',{staticClass:"flix-flex flix-flex-inline flix-flex-center flex-gap-5"},[_c('inputText',{staticStyle:{"width":"100%","box-sizing":"border-box"},attrs:{"data":_vm.service.price,"callback":function(value, all) {
          _vm.setPrice(all, _vm.index);
        },"help":_vm.$t('services.price.info'),"error":false,"styling":{
        input:
          'width: 100%; max-width: 100px; display: block; box-sizing: border-box'
      },"placeholder":_vm.$t('services.price.description'),"label":_vm.$t('services.price.title')}})],1),_c('serviceTags',{attrs:{"index":_vm.index}}),_c('inputTextarea',{staticStyle:{"width":"100%","box-sizing":"border-box"},attrs:{"data":_vm.service.description,"callback":function(value, all) {
        _vm.setDescription(all, _vm.index);
      },"help":_vm.$t('services.description.info'),"error":false,"styling":{
      input:
        'width: 100%; max-width: 600px; display: block; box-sizing: border-box'
    },"placeholder":_vm.$t('services.description.title'),"label":_vm.$t('services.description.title')}}),_c('div',{staticClass:"flix-form-group"},[_c('label',{staticClass:"flix-label",staticStyle:{"display":"block","margin-bottom":"10px"}},[_vm._v(_vm._s(_vm.$t("services.image.add")))]),_c('uploadImg',{key:_vm.service.img,attrs:{"settings":_vm.getUpload(_vm.index)}}),(_vm.service.img)?_c('a',{staticClass:"flix-html-a flix-text-danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return (function () { _vm.deleteImage(_vm.index) }).apply(null, arguments)}}},[_c('icon',{attrs:{"id":"bin"}})],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }